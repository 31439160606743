:root {
  --primary: #004068;
  --primary2: #004068;
  --selected: #004068;
  --counter: #b5b309;
  --q1: #ffc637;
  --q2: #88d15a;
  --q3: #00a78a;
  --q4: #008bec;
  --q5: #00cdd3;
  --q6: #f9716a;
}

body {
  color: var(--primary);
  background-color: #0000;
  background-image: radial-gradient(#fff 20%, #fffc 100%), url("background.dbc15c82.svg");
  background-position: 0 0, 0 0;
  background-repeat: repeat, repeat;
  background-size: cover;
  background-attachment: fixed;
  background-origin: padding-box, padding-box;
  background-clip: border-box, border-box;
  margin: 0;
}

h1 {
  justify-content: center;
  font-family: Victor Mono;
  font-size: 5em;
  font-weight: 100;
  display: flex;
}

.questions {
  flex-direction: column;
  font-family: sans-serif;
  display: flex;
}

.question h2 {
  margin: 0% 0% 5pt;
  font-family: sans-serif;
  font-size: xx-large;
}

body > div > div {
  justify-content: center;
  display: flex;
}

body > div > div > p {
  text-align: center;
  width: 80%;
  margin: 0% 0% 35px;
  font-family: sans-serif;
  font-size: x-large;
  display: flex;
}

.question h3 {
  color: #999;
  font-family: sans-serif;
  font-size: large;
  font-weight: normal;
  position: absolute;
  right: 25%;
}

.question {
  flex-direction: column;
  align-items: center;
  margin: 0%;
  display: flex;
}

.question > div:first-child {
  margin-bottom: 5px;
  display: flex;
}

.question > div:first-child > div:nth-child(2) {
  display: flex;
}

.filledwidget {
  color: #fff;
  border-radius: 30px 30% 30px 30px;
  width: 120px;
  height: 100px;
}

.questions > div > div > button {
  color: var(--primary2);
  vertical-align: top;
  z-index: 1;
  background-color: #0000;
  border: 3px solid #999;
  width: 120px;
  height: 100px;
  margin: 0 -1.5px -1.5px;
}

.button-selected.q1 {
  background-color: var(--q1);
}

.button-selected.q2 {
  background-color: var(--q2);
}

.button-selected.q3 {
  background-color: var(--q3);
}

.button-selected.q4 {
  background-color: var(--q4);
}

.button-selected.q5 {
  background-color: var(--q5);
}

.button-selected.q6 {
  background-color: var(--q6);
}

.questions > div > div > button:first-of-type {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.questions > div > div > button:last-of-type {
  border-top-right-radius: 30%;
  border-bottom-right-radius: 30px;
}

.questions > div > div > button.button-selected {
  color: var(--selected);
  border-color: var(--primary);
  z-index: 10;
  font-weight: bold;
  position: relative;
}

.number {
  background-color: var(--primary);
  color: var(--counter);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  margin-right: auto;
  font-family: sans-serif;
  font-size: xxx-large;
  display: flex;
}

.path {
  color: var(--primary);
  background-color: var(--primary);
  width: 5px;
  height: 120px;
  margin: 20px;
  font-family: sans-serif;
  font-size: xx-large;
}

.submitbutton {
  color: var(--counter);
  border: 3px solid var(--primary);
  vertical-align: top;
  background-color: var(--primary);
  z-index: 1;
  border-radius: 10px;
  margin: -1px -1px 50pt;
  font-size: xx-large;
}

.submit {
  background-size: cover;
  background-attachment: fixed;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-family: sans-serif;
  display: flex;
}

.submit > h1 {
  justify-content: center;
  font-family: monospace;
  font-size: 5em;
  font-weight: 100;
  display: flex;
}

.submit > div {
  justify-content: center;
  width: 90%;
  margin: 20pt;
  font-weight: 100;
  display: flex;
}

.submit > div > div {
  font-size: x-large;
  font-weight: 100;
}

.submit > div > input {
  justify-content: center;
  width: 90%;
  font-family: monospace;
  font-size: 3em;
  font-weight: 100;
  display: flex;
}

.submit > div > div > button {
  color: var(--counter);
  border: 3px solid var(--primary);
  vertical-align: top;
  background-color: var(--primary);
  z-index: 1;
  border-radius: 10px;
  margin: -10pt -1px 50pt;
  font-size: xx-large;
  font-weight: 100;
}

body > div {
  flex-direction: column;
  align-items: center;
  display: flex;
}

body > div > p {
  text-align: center;
  justify-content: center;
  width: 80%;
  margin: 0% 0% 35px;
  font-family: sans-serif;
  font-size: x-large;
  display: flex;
}

body > div > p:last-child {
  margin-top: 3em;
  margin-bottom: 2em;
}

.yearplan {
  flex-direction: row;
  width: 90%;
  margin-bottom: -2em;
  font-family: sans-serif;
  display: flex;
}

.yearplan:nth-child(odd) {
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.yearplan:nth-child(2n) {
  flex-direction: row;
  justify-content: flex-start;
}

.yearplan > div:first-child {
  flex-grow: 1;
  flex-basis: 2em;
}

.yearplan > div:nth-child(3) {
  flex-grow: 1;
  flex-basis: 0;
  padding-left: 1em;
  padding-right: 1em;
}

.yearplan > div:nth-child(2) {
  background-color: var(--primary);
  align-items: stretch;
  width: 5px;
}

.yearplan h2 {
  text-align: center;
  margin: 0;
  font-size: 2em;
  animation-name: changeColor;
}

.progress {
  width: 100%;
}

.progress > div {
  background-color: #d3d3d3;
  justify-content: flex-start;
  width: 70%;
  height: 20px;
  display: flex;
}

.progress > div > div {
  background-color: var(--primary);
  width: 30%;
  height: 20px;
  margin: 0%;
  animation: 60s progress_move;
}

@keyframes progress_move {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}

/*# sourceMappingURL=index.8c2b375d.css.map */
