 :root {
     --primary: #004068;
     --primary2: #004068;
     --selected: #004068;
     --counter: #B5B309;
     --q1: #ffc637;
     --q2: #88d15a;
     --q3: #00a78a;
     --q4: #008bec;
     --q5: #00cdd3;
     --q6: #f9716a;
     /* --bac */
 }

 body {
     margin: 0;
     /* background: linear-gradient(rgba(255, 255, 255, 0.5), rgb(255, 255, 255)), url(download.svg); */
     background: radial-gradient(rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 0.8) 100%), url(background.svg);
     color: var(--primary);
     background-attachment: fixed;
     background-size: cover;

 }

 h1 {
     font-size: 5em;
     font-family: 'Victor Mono';
     font-weight: 100;
     display: flex;
     justify-content: center;
 }

 .questions {
     display: flex;
     flex-direction: column;
     font-family: sans-serif;
 }

 .question h2 {
     /* .questions>div>h2 { */
     font-family: sans-serif;
     font-size: xx-large;
     margin: 0%;
     margin-bottom: 5pt;
 }

 body>div>div {
     display: flex;
     justify-content: center;
 }

 body>div>div>p {
     display: flex;
     font-family: sans-serif;
     font-size: x-large;
     width: 80%;
     margin: 0%;
     text-align: center;
     margin-bottom: 35px;
 }


 .question h3 {
     /* display: flex; */

     font-family: sans-serif;
     font-size: large;
     font-weight: normal;
     /* margin: 0%; */
     /* margin-bottom: 10px; */
     /* margin-left: 600px; */
     /* margin-top: -50px; */
     color: #999999;

     right: 25%;
     position: absolute;
 }

 .question {
     display: flex;
     flex-direction: column;
     align-items: center;
     margin: 0%;

 }

 .question>div:first-child {
     display: flex;
     margin-bottom: 5px;
 }

 .question>div:first-child>div:nth-child(2) {
     display: flex;
 }


 .filledwidget {
     color: #ffffff;
     width: 120px;
     height: 100px;
     border-top-left-radius: 30px;
     border-bottom-left-radius: 30px;
     border-top-right-radius: 30%;
     border-bottom-right-radius: 30px;

 }

 .questions>div>div>button {

     margin: -1.5px;
     width: 120px;
     height: 100px;
     color: var(--primary2);
     border: 3px solid #999999;
     vertical-align: top;
     background-color: transparent;
     z-index: 1;
     margin-top: 0pt;
     /* border-right: 1.5px; */
 }

 .button-selected.q1 {
     background-color: var(--q1);
 }


 .button-selected.q2 {
     background-color: var(--q2);
 }

 .button-selected.q3 {
     background-color: var(--q3);
 }

 .button-selected.q4 {
     background-color: var(--q4);
 }

 .button-selected.q5 {
     background-color: var(--q5);
 }

 .button-selected.q6 {
     background-color: var(--q6);
 }


 .questions>div>div>button:first-of-type {
     border-top-left-radius: 30px;
     border-bottom-left-radius: 30px;
 }

 .questions>div>div>button:last-of-type {
     border-top-right-radius: 30%;
     border-bottom-right-radius: 30px;
 }

 .questions>div>div>button.button-selected {
     color: var(--selected);
     font-weight: bold;
     border-color: var(--primary);
     position: relative;
     z-index: 10;
 }

 .number {
     font-family: sans-serif;
     border-radius: 50%;
     width: 60px;
     height: 60px;
     background-color: var(--primary);
     color: var(--counter);
     font-size: xxx-large;
     display: flex;
     justify-content: center;
     align-items: center;
     margin-right: auto;
 }

 .path {
     color: var(--primary);
     font-family: sans-serif;
     font-size: xx-large;
     width: 5px;
     height: 120px;
     background-color: var(--primary);
     margin: 20px;
 }

 .submitbutton {
     margin: -1px;
     color: var(--counter);
     border-radius: 10px;
     border: 3px solid var(--primary);
     vertical-align: top;
     background-color: var(--primary);
     z-index: 1;
     font-size: xx-large;
     margin-bottom: 50pt;
 }



 .submit {
     display: flex;
     flex-direction: column;
     background-attachment: fixed;
     background-size: cover;
     height: 100%;
     width: 100%;
     font-family: sans-serif;
 }

 .submit>h1 {
     font-size: 5em;
     font-family: monospace;
     font-weight: 100;
     display: flex;
     justify-content: center;
 }

 .submit>div {
     font-weight: 100;
     display: flex;
     justify-content: center;
     margin: 20pt;
     width: 90%;
 }

 .submit>div>div {
     font-size: x-large;
     font-weight: 100;
 }

 .submit>div>input {
     font-weight: 100;
     display: flex;
     width: 90%;
     justify-content: center;
     font-size: 3em;
     font-family: monospace;

 }

 .submit>div>div>button {
     font-size: x-large;
     font-weight: 100;
     margin: -1px;
     color: var(--counter);
     border-radius: 10px;
     border: 3px solid var(--primary);
     vertical-align: top;
     background-color: var(--primary);
     z-index: 1;
     font-size: xx-large;
     margin-bottom: 50pt;
     margin-top: -10pt;

 }

 body>div {
     display: flex;
     flex-direction: column;
     align-items: center;
 }

 body>div>p {
     display: flex;
     font-family: sans-serif;
     font-size: x-large;
     width: 80%;
     margin: 0%;
     text-align: center;
     justify-content: center;
     margin-bottom: 35px;
 }

 body>div>p:last-child {
     margin-top: 3em;
     margin-bottom: 2em;
 }

 .yearplan {
     display: flex;
     flex-direction: row;
     width: 90%;
     margin-bottom: -2em;
     font-family: sans-serif;
 }

 .yearplan:nth-child(odd) {
     flex-direction: row-reverse;
     justify-content: flex-end;

 }

 .yearplan:nth-child(even) {
     flex-direction: row;
     justify-content: flex-start
 }

 .yearplan>div:nth-child(1) {
     flex-grow: 1;
     flex-basis: 2em
 }

 .yearplan>div:nth-child(3) {
     flex-grow: 1;
     flex-basis: 0px;
     padding-left: 1em;
     padding-right: 1em;
 }

 .yearplan>div:nth-child(2) {
     width: 5px;
     align-items: stretch;
     background-color: var(--primary)
 }


 .yearplan h2 {
     font-size: 2em;
     text-align: center;
     animation-name: changeColor;
     margin: 0pt;
     /* animation-duration: 5s;
    animation-iteration-count: infinite; */

 }

 .yearplan ul,
 .yearplan li {
     /* font-size: 2pt; */
 }

 .progress {
     width: 100%;
 }

 .progress>div {
     width: 70%;
     height: 20px;
     background-color: lightgray;
     display: flex;
     justify-content: flex-start;

 }

 .progress>div>div {
     width: 30%;
     margin: 0%;
     height: 20px;
     background-color: var(--primary);
     animation: progress_move 60s;
 }

 @keyframes progress_move {
     from {
         width: 0%;
     }

     to {
         width: 100%;
     }

 }